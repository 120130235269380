import { faBox, faCheck, faMoneyBill, faReceipt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Icon, Tag } from 'react-bulma-components'
import { useTranslation } from 'react-i18next'

const OrderStatus = ({ status }) => {
  const { t } = useTranslation('order')
  const statusData = React.useMemo(() => {
    switch (status) {
    case 'UPLOAD':
      return {
        icon: faReceipt,
        label: t(`status.${status}`),
        color: 'secondary'
      }
    case 'PREPARING':
      return {
        icon: faBox,
        label: t(`status.${status}`),
        color: 'warning'
      }
    case 'SENDED':
      return {
        icon: faCheck,
        label: t(`status.${status}`),
        color: 'info'
      }
    case 'INVOICED':
      return {
        icon: faMoneyBill,
        label: t(`status.${status}`),
        color: 'success'
      }

    default:
      return {}
    }
  }, [status])
  return (
    <Tag.Group hasAddons>
      <Tag color='grey'>
        <Icon>
          <FontAwesomeIcon icon={statusData.icon} />
        </Icon>
      </Tag>
      <Tag color={statusData.color} className='is-light'>
        {statusData.label}
      </Tag>
    </Tag.Group>
  )
}
export default OrderStatus