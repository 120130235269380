import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../../../components/layout'
import Seo from '../../../components/seo'
import { Columns, Container, Element, Heading, Section, Table, Button, Icon, Tag, Loader } from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { useOrders, useOrdersRequest } from '../../../stores/orders'
import { useTranslation } from 'react-i18next'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import { useSession } from '../../../stores/session'
import OrderStatus from '../../../components/orders/status'
import { round } from 'lodash'

const SingleOrderPage = ({ id }) => {
  const [state, actions] = useOrders()
  const [sessionState] = useSession()

  const order = React.useMemo(() => state.currentMafact, [state.currentMafact])
  const [request] = useOrdersRequest({ key: 'get /orders/mafact' })


  const { t } = useTranslation('single-order')

  const location = useLocation()
  const query = queryString.parse(location.search)

  React.useEffect(() => {
    actions.getMafact(query.ref)
  }, [query.ref])

  return (
    <Layout pro>

      <Section>
        {
          ((!request || request.status === 'inProgress') && !state.mafact[0]) &&
            <Container><Loader mt={5} style={{ fontSize: 30 }}/></Container>
        }
        {
          order && order.Mafact === query.ref &&
            <Container>
              <Columns>
                <Columns.Column>
                  <Heading size={5} mb={5} textColor='dark' textWeight='normal'>
                    {t('title')} { order.Mafact }
                    <Tag.Group hasAddons display='inline-block' ml={5}>
                      <OrderStatus status={order.Statut}/>
                    </Tag.Group>
                  </Heading>
                  <Heading subtitle size='7'>
                    <Element renderAs={Link} to='/account/orders' textColor='grey-dark'>&nbsp;← {t('back')}</Element>
                  </Heading>
                </Columns.Column>
                <Columns.Column>
                  {
                    order.Mafact.match(/^VEN/) &&
                      <Button.Group pull='right'>
                        <Button size='small' color='primary' outlined renderAs='a' href={`${process.env.API_HOST}/orders/${order.Mafact}/download`} target='__blank'>
                          <Icon>
                            <FontAwesomeIcon icon={faDownload} />
                          </Icon>
                          <span>
                            {t('invoice')}
                          </span>
                        </Button>
                      </Button.Group>
                  }
                </Columns.Column>
              </Columns>
              <Columns gap={8}>
                <Columns.Column narrow>
                  <Table.Container>
                    <Table hoverable className='first-column-fade'>
                      <Element renderAs='thead' textWeight='bold'>
                        <tr>
                          <td colSpan='2'>
                            {t('infos.title')}
                          </td>
                        </tr>
                      </Element>
                      <tbody>
                        <tr>
                          <td>{t('infos.date')}</td>
                          <td>{ order.Date }</td>
                        </tr>
                        <tr>
                          <td>{t('infos.ref')}</td>
                          <td>{order.Mafact}</td>
                        </tr>
                        <tr>
                          <td>{t('infos.client')}</td>
                          <td>{sessionState.currentOrganization.organization.name}</td>
                        </tr>
                        { /*} <tr>
                          <td>Utilisateur</td>
                          <td>{order.creator ? `${order.creator.firstName[0]}. ${order.creator.lastName}` : 'N/A'}</td>
        </tr>*/ }
                        <tr>
                          <td>{t('infos.price')}</td>
                          <td>{ order.Total } €</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Table.Container>
                </Columns.Column>
                <Columns.Column narrow>
                  <Table.Container>
                    <Table hoverable className='first-column-fade'>
                      <Element renderAs='thead' textWeight='bold'>
                        <tr>
                          <td colSpan='2'>
                            {t('address.title')}
                          </td>
                        </tr>
                      </Element>
                      <tbody>
                        <tr>
                          <td>{t('address.street')}</td>
                          <td>{ order.ADRESSE1 }</td>
                        </tr>
                        <tr>
                          <td>{t('address.city')}</td>
                          <td>{ order.LOC }</td>
                        </tr>
                        <tr>
                          <td>{t('address.country')}</td>
                          <td>{ order.PAYS }</td>
                        </tr>
                        <tr>
                          <td>{t('address.comment')}</td>
                          <td>{ order.comment }</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Table.Container>
                </Columns.Column>
              </Columns>
              <Heading size={5} mb={5} mt={5} textColor='dark' textWeight='normal'>
                {t('products.title')}
              </Heading>
              <Table.Container>
                <Table hoverable size='fullwidth'>
                  <Element renderAs='thead' textWeight='bold'>
                    <tr>
                      <td>
                        {t('products.ref')}
                      </td>
                      <td>
                        {t('products.name')}
                      </td>
                      <Element textAlign='right' renderAs='td'>
                        {t('products.quantity')}
                      </Element>
                      <Element textAlign='right' renderAs='td'>
                        {t('products.unit_price')}
                      </Element>
                      <Element textAlign='right' renderAs='td'>
                        {t('products.total_price')}
                      </Element>
                    </tr>
                  </Element>
                  <tbody>
                    {
                      order.Detail.filter(order => order.Qty).map(orderProduct =>
                        <tr key={ orderProduct._id }>
                          <td>{ orderProduct.Mafact.trim() }</td>
                          <td>{ orderProduct.Lib.trim() }</td>
                          <Element textAlign='right' renderAs='td'>{ orderProduct.Qty }</Element>
                          <Element textAlign='right' renderAs='td'>{ orderProduct.Punit } €</Element>
                          <Element textAlign='right' renderAs='td'>{ round(Number(orderProduct.Punit) * Number(orderProduct.Qty), 2)} €</Element>
                        </tr>
                      )
                    }
                  </tbody>
                </Table>
              </Table.Container>
            </Container>
        }
      </Section >
    </Layout >
  )
}
export default SingleOrderPage

export const query = graphql`
  query($language: String!) {
          locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
          node {
          ns
          data
          language
        }
      }
    }
  }
`