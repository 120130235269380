import { getYear } from 'date-fns'
import { sortBy } from 'lodash'
import { createHook, createStore } from 'react-sweet-state'
import { apiCall } from '../core/store'

// This is the value of the store on initialisation
const initialState = {
  endpoint: '/orders',
  current: undefined,
  all: [],
  mafact: [],
  currentMafact: undefined,
  filters: { year: getYear(new Date()) }
}

// All the actions that mutate the store
const actions = {
  fetch: (filters) => async ({ setState, dispatch }) => {
    const res = await dispatch(apiCall({ params: { ...filters, sort: '-dates.created' } }))
    res && res.result && setState({ all: res.result })
  },
  fetchMafact: (from, to, mafactRef) => async ({ setState, getState, dispatch }) => {
    setState({ mafact: [] })
    const res = await await dispatch(apiCall({
      endpoint: `${getState().endpoint}/mafact`,
      cancelable: true,
      params: {
        from, to, Mafact: mafactRef
      }
    }))
    if (res && res.result && mafactRef)
      setState({ currentMafact: res.result[0] })
    else if (res && res.result)
      setState({ mafact: sortBy(res.result, [function (o) { return o.Mafact.substring(3) }]).reverse() })
  },
  getMafact: (ref) => async ({ setState, getState, dispatch }) => {
    const existingInStore = getState().mafact.find(order => order.Mafact === ref)
    if (existingInStore) {
      setState({ currentMafact: existingInStore })
    } else {
      await dispatch(actions.fetchMafact(undefined, undefined, ref))
    }
  },
  get: (id) => async ({ setState, getState, dispatch }) => {
    const res = await dispatch(apiCall({
      endpoint: `${getState().endpoint}/${id}`,
      params: {
        populate: ['products.item', 'creator', 'organization']
      }
    }))
    res && res.result && setState({ current: res.result })
  },
  create: (params, prices) => async ({ setState, getState, dispatch }) => {
    const { basket } = params
    if (!basket)
      throw 'Missing basket'
    await dispatch(
      apiCall({
        params: {
          products: basket.filter(product => product.quantity).map(product => ({
            ...product,
            quantity: Number(product.quantity),
            unitPrice: product.product && product.product._id && prices[product.product._id] && prices[product.product._id].PHTAX,
            totalPrice: product.product && product.product._id && prices[product.product._id] && Number(product.quantity) * prices[product.product._id].PHTAX,
          })),
          ...params
        },
        method: 'post'
      })
    )
  },
  clearRequest: (params = {}) => ({ getState, setState }) => {
    const requests = { ...getState().requests }
    delete requests[params.key]
    setState({ requests })
  },
  setFilters: (filters = {}) => ({ getState, setState }) => {
    setState({ filters })
  }
}

const Store = createStore({ initialState, actions, name: 'orders' })

export const useOrdersRequest = createHook(Store, {
  selector: (state, props) => state.requests && state.requests[props.key]
})

export const useOrders = createHook(Store)
